import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'const',
  standalone: true,
})
export class ConstPipe implements PipeTransform {
  private readonly constantsMapping: { [key: string]: string } = {
    Hour: 'h',
    Hours: 'h',
    Stunde: 'h',
    Stunden: 'h',
    'Sprint(s)': 'SP',
    Unit: 'U',
    Euro: '€',
  };

  transform(value: string): string {
    return this.constantsMapping[value] || '';
  }
}
