<mat-toolbar>
  <mat-toolbar-row>
    <a routerLink="/"
      ><img alt="pep.digital logo" ngSrc='assets/pep_digital.png'
            height='200' width='997' priority/></a>
    <span class="spacer"></span>
    <button
      (click)="toggleMenu()"
      aria-label="Example icon-button with menu icon"
      class="example-icon mobile"
      mat-icon-button
      >
      <mat-icon>menu</mat-icon>
    </button>
    <div class="desktop">
      <button mat-button routerLink="/projects">{{ 'PROJECTS' | translate }}</button>
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>language</mat-icon>
        {{ language }}
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="setLanguage('DE')">
          DE
        </button>
        <button mat-menu-item (click)="setLanguage('EN')">
          EN
        </button>
      </mat-menu>
      <button mat-button routerLink="/profile">
        <mat-icon>account_circle</mat-icon>
        {{ 'PROFILE' | translate }}
      </button>
      <button mat-button (click)='authService.logout()'>
        <mat-icon>logout</mat-icon>
        {{ 'LOGOUT' | translate }}
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<div class="mobile">
  @if (menuVisibility) {
    <div (click)="toggleMenu()" class="menu-entries">
      <button mat-button routerLink="/projects">{{ 'PROJECTS' | translate }}</button>
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>language</mat-icon>
        {{ language }}
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="setLanguage('DE')">
          DE
        </button>
        <button mat-menu-item (click)="setLanguage('EN')">
          EN
        </button>
      </mat-menu>
      <button mat-button routerLink="/profile">
        <mat-icon>account_circle</mat-icon>
        {{ 'PROFILE' | translate }}
      </button>
      <button mat-button (click)='authService.logout()'>
        <mat-icon>logout</mat-icon>
        {{ 'LOGOUT' | translate }}
      </button>
    </div>
  }
</div>

@if (isLoading) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
