import { AbstractControl, ValidatorFn } from '@angular/forms';

export function matchValidator(
  firstControl: AbstractControl,
  secondControl: AbstractControl
): ValidatorFn {
  return () => {
    return firstControl.value !== secondControl.value
      ? { match_error: 'Values does not match' }
      : null;
  };
}
