import { Injectable, NgZone } from '@angular/core';
import { SnackBarComponent } from '../../component/snack-bar-component/snack-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

export enum Icon {
  DONE = 'done',
  INFO = 'info_outline',
  WARNING = 'warning_amber',
  ERROR = 'report_gmailerrorred',
}

export interface SnackbarNotification {
  shortMessage: string,
  longMessage: string,
  icon: Icon;
}

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  public notificationQueue: SnackbarNotification[] = [];
  public processingNotification = false;
  MAX_NOTIFICATIONS_IN_QUEUE = 10;
  SNACKBAR_DISPLAYED_IN_MS = 300 * 1000;

  constructor(private _snackBar: MatSnackBar, private _ngZone: NgZone) {}

  addNotification(notification: SnackbarNotification): void {
    if (this.notificationQueue.length < this.MAX_NOTIFICATIONS_IN_QUEUE) {
      this.notificationQueue.push(notification);

      if (!this.processingNotification) {
        this.displaySnackbar();
      }
    }
  }

  displaySnackbar(): void {
    const notification = this.getNextNotification();

    if (!notification) {
      this.processingNotification = false;
      return;
    }

    this.processingNotification = true;

    this._ngZone.run(() => {
      this._snackBar
        .openFromComponent(SnackBarComponent, {
          data: notification,
          duration: this.SNACKBAR_DISPLAYED_IN_MS,
          panelClass: ['error-snackbar'],
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        })
        .afterDismissed()
        .subscribe(() => {
          this.displaySnackbar();
        });
    });
  }

  getNextNotification(): SnackbarNotification | undefined {
    return this.notificationQueue.length ? this.notificationQueue.shift() : undefined;
  }
}
