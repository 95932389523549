import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { AuthService } from './service/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './service/local-storage/local-storage.service';
import { ChangePasswordService } from './service/change-password/change-password.service';
import { NavbarComponent } from './component/navbar/navbar.component';
import { CookieBannerComponent } from './component/cookie-banner/cookie-banner.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NavbarComponent, RouterOutlet, CookieBannerComponent]
})
export class AppComponent implements OnInit {
  url = '';
  isLoggedIn = false;

  constructor(
    private router: Router,
    private dateAdapter: DateAdapter<Date>,
    private authService: AuthService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private changePasswordService: ChangePasswordService
  ) {}

  ngOnInit() {
    this.initAppLanguage();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
    this.authService.getIsUserAuthenticatedSubject().subscribe((result) => {
      this.isLoggedIn = result;
    });

    this.authService.getIsFirstLogin().subscribe((result) => {
      if (result) {
        this.changePasswordService.openDialog();
      }
    });
  }

  initAppLanguage() {
    let lang = this.localStorageService.getLocalStorage('lang');
    if (lang === null) {
      lang = navigator.language.split('-')[0];
    }

    this.localStorageService.setLocalStorage(lang, 'lang');
    this.dateAdapter.setLocale(lang);
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
  }
}
