@if (passwordChangeGroup) {
  <mat-card>
    <h2 class='card-headline'>{{ 'PASSWORD_CHANGE' | translate }}</h2>
    <form [formGroup]='passwordChangeGroup'>
      <h3>{{ 'PASSWORD_NEW' | translate }}</h3>
      <mat-form-field class='password'>
        <mat-label>{{ 'PASSWORD_INSERT' | translate }}</mat-label>
        <input matInput [formControlName]="FORM_PARAMS.NEW_PASSWORD" [type]="isPasswordInvisible ? 'password' : 'text'" />
        <mat-icon matIconSuffix (click)="isPasswordInvisible = !isPasswordInvisible">
          {{ isPasswordInvisible ? 'visibility_off' : 'visibility' }}
        </mat-icon>
      </mat-form-field>
      @if (passwordStrength > 0) {
        <mat-progress-bar
          [ngClass]="{
                'red': passwordStrength <=20,
                'orange': passwordStrength > 20 && passwordStrength <= 40,
                'yellow': passwordStrength > 40 && passwordStrength <= 60,
                'green': passwordStrength > 60 && passwordStrength <= 80,
              }"
          [value]='passwordStrength'>
        </mat-progress-bar>
      }
      <h3>{{ 'PASSWORD_REPEAT' | translate }}</h3>
      <mat-form-field class='password'>
        <mat-label>{{ 'PASSWORD_INSERT' | translate }}</mat-label>
        <input matInput [formControlName]="FORM_PARAMS.NEW_PASSWORD_CHECK" [type]="isPasswordRepeatInvisible ? 'password' : 'text'" />
        <mat-icon matSuffix (click)="isPasswordRepeatInvisible = !isPasswordRepeatInvisible">
          {{ isPasswordRepeatInvisible ? 'visibility_off' : 'visibility' }}
        </mat-icon>
      </mat-form-field>
      @if (passwordChangeGroup.touched && passwordChangeGroup.invalid) {
        @if (passwordChangeGroup.hasError('match_error')) {
          <mat-error>{{ 'PASSWORD_SAME' | translate }}</mat-error>
        }
      }
    </form>
    @if (passwordChangeResult) {
      @if (passwordChangeResult.result) {
        <span class='success-message'>{{ 'PASSWORD_SUCCESS' | translate }}</span>
      } @else {
        <mat-error
        >{{ 'PASSWORD_ERROR' | translate }}<br>
          {{ passwordChangeResult.message }}
        </mat-error>
      }
    }
    <div class='card-actions'>
      @if (isCancelAvailable) {
        <button
          (click)='passwordChangeGroup.reset()'
          [disabled]='passwordChangeGroup.untouched'
          color='grey'
          mat-flat-button
          type='button'
        > {{ 'CANCEL' | translate }}
        </button>
      }
      <button
        (click)='savePassword()'
        [disabled]='passwordChangeGroup.invalid || passwordChangeGroup.disabled || !isPasswordStrong()'
        color='primary'
        mat-flat-button
        type='button'
      >
        @if (passwordChangeGroup.disabled) {
          <mat-spinner diameter="30"></mat-spinner>
        } @else {
          {{ 'SAVE' | translate }}
        }
      </button>
    </div>
  </mat-card>
}
