import { Injectable } from '@angular/core';
import { ErpService } from '../erp/erp.service';
import { ActivityRecord, Project, ProjectEntity } from '../../model/erp';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  projects: Project[] = [];

  constructor(private erpService: ErpService) {}

  getProjectByName(name: string): Promise<Project | undefined> {
    return new Promise((resolve) => {
      this.getProjects().then((projects) => {
        return resolve(projects.filter((prj) => prj.name === name)[0]);
      });
    });
  }

  getProjects(): Promise<Project[]> {
    return new Promise((resolve) => {
      if (this.projects.length > 0) {
        return resolve(this.projects);
      }
      firstValueFrom(this.erpService.getProjects()).then((projects) => {
        this.projects = projects;
        return resolve(this.projects);
      });
    });
  }

  getFullProjectEntity(project: Project): Promise<ProjectEntity> {
    return new Promise((resolve) => {
      const projectEntity: ProjectEntity = { ...project, activity_records: [] };
      firstValueFrom(
        this.erpService.getProjectActivityRecords(project.name)
      ).then((activityRecords: ActivityRecord[]) => {
        projectEntity.activity_records = activityRecords.sort((a, b) => {
          return b.posting_date.localeCompare(a.posting_date);
        });
        return resolve(projectEntity);
      });
    });
  }

  reset(): void {
    this.projects = [];
  }
}
