import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Icon, SnackbarNotification, SnackbarService } from '../snackbar/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  labelTranslation = [''];
  constructor(
    private translateService: TranslateService,
    private snackbarService: SnackbarService,
  ) {
    this.setLabels();
    translateService.onLangChange.subscribe(() => {
      this.setLabels();
    })
  }

  private setLabels() {
    this.translateService.get(['ERROR', 'STATUS_CODE', 'STATUS_TEXT', 'ERROR_OCCURRED', 'ERROR_TYPE', 'ERROR_MESSAGE', 'ERROR_STACKTRACE'])
      .subscribe(t => {
        this.labelTranslation = [t['ERROR'], t['STATUS_CODE'], t['STATUS_TEXT'], t['ERROR_OCCURRED'], t['ERROR_TYPE'], t['ERROR_MESSAGE'], t['ERROR_STACKTRACE']];
    });
  }

  handleError(error: Error | HttpErrorResponse) {
    let errorMessage;
    let additionalMessage;
    if (error instanceof HttpErrorResponse) {
      errorMessage =
        this.labelTranslation[0] + '\n' +
        this.labelTranslation[1] + ": '" + error.status  + '"\n' +
        this.labelTranslation[2] + ": '" + error.statusText + '"';
      additionalMessage = error.message ?? '';
    } else {
      errorMessage =
        this.labelTranslation[3] + '\n' +
        this.labelTranslation[4] + ": '" + error?.name + '"\n' +
        this.labelTranslation[5] + ": '" + error?.message + '"';
      additionalMessage =  this.labelTranslation[6] +': \n' + error.stack ?? '';
    }

    const errorNotification : SnackbarNotification = {
      shortMessage: errorMessage,
      longMessage: errorMessage + '\n' + additionalMessage,
      icon: Icon.ERROR
    }
    this.snackbarService.addNotification(errorNotification);
  }
}
