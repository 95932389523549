import { Injectable } from '@angular/core';
import { ErpService } from '../erp/erp.service';
import { Router } from '@angular/router';
import { ProjectService } from '../project/project.service';
import { catchError, firstValueFrom, map, Observable, of, Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { User } from '../../model/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isUserAuthenticatedSubject: Subject<boolean> = new Subject<boolean>();
  private SESSION_TIMEOUT = 'session_timeout';
  private isFirstLogin: Subject<boolean> = new Subject<boolean>();

  constructor(
    private erpService: ErpService,
    private router: Router,
    private projectService: ProjectService,
    private cookieService: CookieService,
  ) {}

  async isAuthenticated(): Promise<boolean> {
    if (this.cookieService.check(this.SESSION_TIMEOUT)) {
      this.isUserAuthenticatedSubject.next(true);
      return true;
    }

    try {
      const user: User = await firstValueFrom(this.erpService.isUserLoggedIn());
      this.isFirstLogin.next(user.message.reset_password);

      const sessionExpiry: Date = this.getSessionExpiry();
      this.cookieService.set(this.SESSION_TIMEOUT, '', sessionExpiry);
      this.isUserAuthenticatedSubject.next(true);
      return true;

    } catch (error) {
      this.cookieService.delete(this.SESSION_TIMEOUT);
      this.reset();
      return false;
    }
  }

  login(
    email: string,
    password: string,
    redirectUrl: string,
  ): Observable<boolean> {
    return this.erpService.login(email, password).pipe(
      map(() => {
        this.isUserAuthenticatedSubject.next(true);
        this.router.navigate([redirectUrl]);
        return true;
      }),
      catchError(() => {
        return of(false);
      }),
    );
  }

  logout(): void {
    this.cookieService.delete(this.SESSION_TIMEOUT);
    this.erpService.logout().subscribe(() => {
      this.reset();
    });
  }

  getIsUserAuthenticatedSubject(): Observable<boolean> {
    return this.isUserAuthenticatedSubject.asObservable();
  }

  getIsFirstLogin(): Observable<boolean> {
    return this.isFirstLogin.asObservable();
  }

  private reset(): void {
    this.isUserAuthenticatedSubject.next(false);
    this.projectService.reset();
    this.router.navigate(['/login']);
  }

  private getSessionExpiry(): Date {
    const expiryDate: Date = new Date();
    expiryDate.setMinutes(expiryDate.getMinutes() + 15);
    return expiryDate;
  }
}
