import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'translateDate',
  standalone: true,
  pure: false,
})
export class TranslateDatePipe implements PipeTransform {

  constructor(
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) {
  }

  transform(value: any, pattern = 'shortDate'): any {
    if(!value) return value;

    const currentLanguage = this.translateService.currentLang == 'de' ? 'de-DE' : 'en-US';
    let customPattern = pattern;
    if(pattern === 'shortDate') {
      customPattern = (currentLanguage === 'de-DE') ? 'dd.MM.yyyy' : 'MM/dd/yyyy';
    }

    return this.datePipe.transform(value, customPattern, undefined, currentLanguage);
  }
}
