import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  getLoadingState(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  setLoadingState(loading: boolean): void {
    this.isLoading.next(loading);
  }
}
