import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { MatError, MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ErpService } from '../../service/erp/erp.service';
import { matchValidator } from '../../validator/validators';
import { MatIcon } from '@angular/material/icon';
import { MatDialogActions } from '@angular/material/dialog';
import {
  PasswordChangeInformationService
} from '../../service/password-change-information/password-change-information.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatProgressBar } from '@angular/material/progress-bar';
import { SnackBarComponent } from '../snack-bar-component/snack-bar.component';
import { Icon, SnackbarService } from '../../service/snackbar/snackbar.service';
import { zxcvbn } from 'zxcvbn-typescript';
import { NgClass } from '@angular/common';

export const FORM_PARAMS = {
  NEW_PASSWORD: 'newPassword',
  NEW_PASSWORD_CHECK: 'newPasswordCheck'
};

@Component({
  selector: 'app-password-group',
  standalone: true,
  imports: [
    MatButton,
    MatCard,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    TranslateModule,
    MatIcon,
    MatIconButton,
    MatDialogActions,
    MatSuffix,
    MatProgressSpinner,
    MatProgressBar,
    SnackBarComponent,
    NgClass
  ],
  templateUrl: './password-group.component.html',
  styleUrl: './password-group.component.scss'
})
export class PasswordGroupComponent implements OnInit {
  @Input()
  isCancelAvailable: boolean = true;
  @Input()
  isNotificationEnabled: boolean = true;

  protected readonly FORM_PARAMS = FORM_PARAMS;

  isPasswordInvisible = true;
  isPasswordRepeatInvisible = true;
  passwordChangeResult: { result: boolean; message: string } | null = null;
  passwordStrength = 0;

  constructor(
    private erpService: ErpService,
    private passwordChangeInformationService: PasswordChangeInformationService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.passwordChangeGroup.addValidators(
      matchValidator(
        this.passwordChangeGroup.controls[FORM_PARAMS.NEW_PASSWORD],
        this.passwordChangeGroup.controls[FORM_PARAMS.NEW_PASSWORD_CHECK]
      )
    );
    this.passwordChangeGroup.valueChanges.subscribe(
      () => {
        this.passwordChangeResult = null;
        this.passwordStrength = this.calculatePasswordStrength();
      });
  }

  passwordChangeGroup: FormGroup = new FormGroup({
    [FORM_PARAMS.NEW_PASSWORD]: new FormControl('', [
      Validators.required,
    ]),
    [FORM_PARAMS.NEW_PASSWORD_CHECK]: new FormControl('', Validators.required)
  });

  savePassword(): void {
    this.passwordChangeGroup.disable();
    this.erpService
      .updatePassword(this.getPassword())
      .subscribe({
        next: (result) => {
          this.passwordChangeGroup.reset();
          this.passwordChangeGroup.enable();
          this.passwordChangeResult = result;
          this.passwordChangeInformationService.setHasPasswordChange(
            this.passwordChangeResult.result
          );

          if (this.isNotificationEnabled) {
            this.notifyPasswordChangeResult(result);
          }
        },
        error: (error) => {
          this.passwordChangeGroup.enable();
          throw error;
        }
      });
  }

  private notifyPasswordChangeResult(result: {
    result: boolean;
    message: string;
  }) {
    result.result
      ? this.createSuccessNotification(result.message)
      : this.createErrorNotification(result.message);
  }

  private createSuccessNotification(message: string) {
    this.snackbarService.addNotification({
      shortMessage: 'PASSWORD_SUCCESS',
      longMessage: message,
      icon: Icon.DONE
    });
  }

  private createErrorNotification(message: string) {
    this.snackbarService.addNotification({
      shortMessage: 'PASSWORD_ERROR',
      longMessage: message,
      icon: Icon.ERROR
    });
  }

  getPassword(): string {
    return this.passwordChangeGroup.controls[FORM_PARAMS.NEW_PASSWORD].value;
  }

  calculatePasswordStrength() {
    return this.getPassword() ? 20 * (zxcvbn(this.getPassword()).score + 1) : 0;
  }

  isPasswordStrong() {
    return this.passwordStrength > 60
  }
}
