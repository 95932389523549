import {
  AfterViewInit,
  Component,
  Inject,
  ViewEncapsulation
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from '@angular/cdk/clipboard';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBar,
  MatSnackBarModule
} from '@angular/material/snack-bar';
import { SnackbarNotification } from '../../service/snackbar/snackbar.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  imports: [
    MatButtonModule,
    MatSnackBarModule,
    MatIconModule,
    MatTooltipModule,
    ClipboardModule,
    TranslateModule
  ],
  standalone: true,
  encapsulation: ViewEncapsulation.None
})
export class SnackBarComponent implements AfterViewInit {
  displayTooltip = false;
  icon;
  shortMessage;
  longMessage;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    private snackbarNotification: SnackbarNotification,
    private snackBar: MatSnackBar
  ) {
    this.icon = this.snackbarNotification.icon;
    this.shortMessage = this.snackbarNotification.shortMessage;
    this.longMessage = this.snackbarNotification.longMessage;
  }

  ngAfterViewInit() {
    this.displayTooltip = true;
  }

  showCopyToClipboardButton() {
    return this.longMessage !== undefined && this.longMessage !== '' && this.longMessage !== null
  }

  closeSnackbar() {
    this.snackBar.dismiss();
  }
}
