import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PasswordChangeInformationService } from '../password-change-information/password-change-information.service';
import { ChangePasswordComponent } from '../../component/change-password/change-password.component';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  constructor(
    private matDialog: MatDialog,
    private passwordChangeInformationService: PasswordChangeInformationService
  ) {}

  openDialog(): void {
    const dialogRef = this.matDialog.open(ChangePasswordComponent, {
      minWidth: '50rem',
      disableClose: true
    });

    this.passwordChangeInformationService
      .getHasPasswordChange()
      .subscribe((hasPasswordChanged) => {
        if (hasPasswordChanged) {
          dialogRef.close();
        }
      });
  }
}
