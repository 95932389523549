import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import {
  Activity,
  ActivityRecord,
  ErpResponse,
  LoginResponse,
  Project
} from '../../model/erp';
import { ENV } from '../../../environments/environment';
import { Order } from '../../model/order';
import { BurndownRow } from '../../model/burndown-row';
import { BudgetStatusRow } from '../../model/budget-status-row';
import { User } from '../../model/user';

@Injectable({
  providedIn: 'root'
})
export class ErpService {
  constructor(private httpClient: HttpClient) {}

  login(
    email: string,
    password: string
  ): Observable<HttpResponse<LoginResponse>> {
    return this.httpClient.post<LoginResponse>(
      `${this.getApiBaseRoute()}/login`,
      { cmd: 'login', usr: email, pwd: password },
      { observe: 'response' }
    );
  }

  logout(): Observable<void> {
    return this.httpClient.get<void>(`${this.getApiBaseRoute()}/logout`);
  }

  isUserLoggedIn(): Observable<User> {
    return this.httpClient.get<User>(this.getUserLoggedInFullPath());
  }

  getUserLoggedInFullPath() {
    return this.getApiBaseRoute() + '/pep.kupo.query.get_logged_user';
  }

  updatePassword(
    newPassword: string
  ): Observable<{ result: boolean; message: string }> {
    return this.httpClient
      .post<ErpResponse<any>>(
        `${this.getApiBaseRoute()}/pep.kupo.handlers.update_password`,
        {
          new_password: newPassword
        }
      )
      .pipe(
        map((res) => ({
          result: res.message.Result,
          message: res.message.Message
        }))
      );
  }

  getProjects(): Observable<Project[]> {
    return this.httpClient
      .get<ErpResponse<Project>>(
        `${this.getApiBaseRoute()}/pep.kupo.query.get_projects`
      )
      .pipe(this.unpack);
  }

  getProjectActivityRecords(projectName: string): Observable<ActivityRecord[]> {
    return this.httpClient
      .post<ErpResponse<ActivityRecord>>(
        `${this.getApiBaseRoute()}/pep.kupo.query.get_activity_records`,
        { project: projectName }
      )
      .pipe(this.unpack);
  }

  getSalesOrders(projectName: string): Observable<Order[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('project', projectName);

    return this.httpClient
      .get<ErpResponse<Order>>(
        `${this.getApiBaseRoute()}/pep.kupo.query.get_sales_orders`,
        { params: queryParams }
      )
      .pipe(this.unpack);
  }

  getBudgetStatusRows(projectName: string): Observable<BudgetStatusRow[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('project', projectName);

    return this.httpClient
      .get<ErpResponse<BudgetStatusRow>>(
        `${this.getApiBaseRoute()}/pep.kupo.query.get_project_budgets`,
        { params: queryParams }
      )
      .pipe(this.unpack);
  }

  getProjectIterations(projectName: string): Observable<BurndownRow[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('project', projectName);

    return this.httpClient
      .get<ErpResponse<BurndownRow>>(
        `${this.getApiBaseRoute()}/pep.kupo.query.get_project_iterations`,
        { params: queryParams }
      )
      .pipe(this.unpack);
  }

  confirmActivityRecord(
    activityRecordName: string,
    signature: string
  ): Observable<any> {
    return this.httpClient.post(
      `${this.getApiBaseRoute()}/pep.kupo.handlers.confirm_activity_record`,
      {
        activity_record: activityRecordName,
        signature: signature
      }
    );
  }

  getProjectActivities(
    projectName: string,
    start: string,
    end: string
  ): Observable<Activity[]> {
    return this.httpClient
      .post<ErpResponse<Activity>>(
        `${this.getApiBaseRoute()}/pep.kupo.query.get_activities`,
        {
          project: projectName,
          start,
          end
        }
      )
      .pipe(this.unpack);
  }

  getActivityRecordAsPDFPath(name: string) {
    return `${this.getApiBaseRoute()}/pep.kupo.query.get_activity_record_pdf?activity_record=${name}`;
  }

  getInvoiceAsPDFPath(name: string) {
    return `${this.getApiBaseRoute()}/pep.kupo.query.get_sales_invoice_pdf?activity_record=${name}`;
  }

  unpack<T>(observable: Observable<ErpResponse<T>>): Observable<T[]> {
    return observable.pipe(map((res: ErpResponse<T>) => res.message as T[]));
  }

  getApiBaseRoute(): string {
    if (localStorage.getItem('stage') === 'dev') {
      return ENV.devRoute;
    } else if (localStorage.getItem('stage') === 'prod') {
      return ENV.prodRoute;
    } else {
      return '/api/method';
    }
  }
}
