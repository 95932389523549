import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  getLocalStorage(key: string): string | null{
    return localStorage.getItem(key);
  }

  setLocalStorage(value: boolean | string, key: string) {
    localStorage.setItem(key, value.toString());
  }

  getOrSetVariableInLocalStorage(
    defaultValue: boolean,
    key: string,
  ): boolean {
    const existingValue = localStorage.getItem(key);

    if (existingValue === null) {
      this.setLocalStorage(defaultValue, key);
      return defaultValue;
    } else {
      return JSON.parse(existingValue);
    }
  }
}
