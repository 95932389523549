import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PasswordChangeInformationService {
  private hasPasswordChanged = new Subject<boolean>();

  setHasPasswordChange(result: boolean) {
    this.hasPasswordChanged.next(result);
  }

  getHasPasswordChange() {
    return this.hasPasswordChanged.asObservable();
  }
}
