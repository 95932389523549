import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ErpService } from '../erp/erp.service';
import { inject } from '@angular/core';

export const HTTP_ERROR_INTERCEPTOR: HttpInterceptorFn = (req, next) => {
  const erpService = inject(ErpService);
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (
        error.status == 403 &&
        error.url?.includes(erpService.getUserLoggedInFullPath())
      ) {
        return next(req);
      } else {
        throw error;
      }
    })
  );
};
