import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { HttpClient, HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { CustomDateAdapter } from './adapter/custom-date-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';

import { ROUTES } from './app.routes';
import { TranslateDatePipe } from './pipe/translate-date.pipe';
import { ConstPipe } from './pipe/const.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { HTTP_ERROR_INTERCEPTOR } from './service/http-error-interceptor/http-error-interceptor.service';
import { ErrorHandlerService } from './service/error-handler/error-handler.service';

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export const APP_CONFIG: ApplicationConfig = {
  providers: [

    provideHttpClient(withInterceptors([HTTP_ERROR_INTERCEPTOR])),
    importProvidersFrom(
      BrowserAnimationsModule,
      HttpClientModule,
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient]
        }
      })
    ),
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    ChangePasswordComponent,
    DecimalPipe,
    TranslateService,
    DatePipe,
    ConstPipe,
    TranslateDatePipe,
    provideRouter(
      ROUTES,
      withInMemoryScrolling({ anchorScrolling: 'enabled' })
    ),
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'input'
        },
        display: {
          dateInput: 'input',
          monthYearLabel: { year: 'numeric', month: 'numeric' },
          dateA11yLabel: {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          },
          monthYearA11yLabel: { year: 'numeric', month: 'long' }
        }
      }
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
  ]
};
