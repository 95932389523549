import { NativeDateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable(
  { providedIn: 'root' }
)
export class CustomDateAdapter extends NativeDateAdapter {
  constructor(private translateService: TranslateService) {
    super('de-DE');
    this.setLocale(this.translateService.currentLang);

    this.translateService.onLangChange.subscribe(lang => {
      this.setLocale(lang.lang);
    });
  }
}
