import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../service/local-storage/local-storage.service';
import { MatButtonModule } from '@angular/material/button';


@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule
]
})
export class CookieBannerComponent implements OnInit {
  defaultValue = true;
  isCookieBannerDisplayed = this.defaultValue;
  localStorageVariableName = 'displayCookieBanner';

  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    this.isCookieBannerDisplayed =
      this.localStorageService.getOrSetVariableInLocalStorage(
        this.defaultValue,
        this.localStorageVariableName,
      );
  }

  switchCookieBannerState() {
    this.localStorageService.setLocalStorage(
      !this.isCookieBannerDisplayed,
      this.localStorageVariableName,
    );
    this.isCookieBannerDisplayed = !this.isCookieBannerDisplayed;
  }
}
