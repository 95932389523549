import { Routes } from '@angular/router';
import { IS_AUTH_GUARD } from './security/auth.guard';

export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'projects',
    pathMatch: 'full',
  },
  {
    path: 'projects',
    children: [
      {
        path: '',
        loadComponent: () => import('./page/projects-page/projects-page.component').then(m => m.ProjectsPageComponent) ,
        canActivate: [IS_AUTH_GUARD],
      },
      {
        path: 'project/:name/:tab',
        loadComponent: () => import('./page/project-page/project-page.component').then(m => m.ProjectPageComponent) ,
        canActivate: [IS_AUTH_GUARD],
      },
    ],
  },
  {
    path: 'profile',
    loadComponent: () => import('./page/profile-page/profile-page.component').then(m => m.ProfilePageComponent) ,
    canActivate: [IS_AUTH_GUARD],
  },
  {
    path: 'login',
    loadComponent: () => import('./page/login-page/login-page.component').then(m => m.LoginPageComponent) ,
  },
  {
    path: '**',
    redirectTo: 'projects',
  },
];
