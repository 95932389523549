import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoadingService } from '../../service/loading/loading.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../../service/local-storage/local-storage.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { AuthService } from '../../service/auth/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    TranslateModule,
    RouterLink,
    MatButtonModule,
    MatProgressBarModule,
    NgIf,
    NgOptimizedImage
]
})
export class NavbarComponent implements OnInit {
  menuVisibility = false;
  isLoading = false;
  language = '';

  constructor(
    private loadingService: LoadingService,
    private ref: ChangeDetectorRef,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    protected authService: AuthService
  ) {
  }

  ngOnInit() {
    this.loadingService.getLoadingState().subscribe((loading) => {
      this.isLoading = loading;
      this.ref.detectChanges();
    });
    const lang = this.localStorageService.getLocalStorage('lang');
    lang === null ? this.language = 'DE' : this.language = lang.toUpperCase();
  }

  toggleMenu() {
    this.menuVisibility = !this.menuVisibility;
  }

  setLanguage(language: string) {
    this.language = language;
    this.translateService.use(language.toLowerCase());
    this.localStorageService.setLocalStorage(language.toLowerCase(), 'lang');
  }

  logout() {
    this.authService.logout();
  }
}
