@if (isCookieBannerDisplayed) {
  <div id="cookieBanner">
    <h3>Cookies</h3>
    <div id="bannerContent">
      <p>Es werden lediglich technisch notwendige Cookies eingesetzt</p>
      <button color="primary" mat-flat-button (click)="switchCookieBannerState()">
        Ok
      </button>
    </div>
  </div>
}
