<div class="snackbar-wrapper">
    <span matSnackBarLabel class="text-wrapper">
      <mat-icon class="snackbarLabel snackbarLabelIcon"> {{ icon }} </mat-icon>
      <span class="snackbarLabel">{{ shortMessage | translate }}</span>
    </span>

  <span matSnackBarActions>
    @if(showCopyToClipboardButton()) {
      <button
        matTooltipPosition="above"
        [matTooltip]="displayTooltip ? 'Copy' : ''"
        class="actionButton"
        mat-mini-fab
        color="primary"
        aria-label="Copy Message Button"
        [cdkCopyToClipboard]="longMessage"
      >
      <mat-icon>content_copy</mat-icon>
    </button>
    }
    <button
      matTooltipPosition="above"
      [matTooltip]="displayTooltip ? 'Close' : ''"
      class="actionButton"
      mat-mini-fab
      color="primary"
      aria-label="Close Snack Bar Button"
      (click)="closeSnackbar()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </span>
</div>
